/* global $ sspModules */

var pluginData = {
    name: "imageCompression",
    version: "0.9",
    modified: "2016-11",
    selfrunning: false,
    dependencies: [
        "jQuery",
    ]
};

var plugin = {
    imageCompression: function(config) {
        var defaults;

        defaults = {
            images: $("[data-responsiveimage='true']:not(.skipimage)"),
            skippedImages : $("[data-responsiveimage='true'].skipimage"),
            log: false,
            callback: false,
            force_size: false,
            addon_settings : false
        };

        var settings = $.extend(defaults, config);
        var org_settings = settings;

        if (settings.log === true)
            console.log("running", settings);

        settings.images.attr("src", function() {
            // console.log("image changed", $(this).data("original"))
            if (typeof $(this).data("original") == "undefined")
                return;

            if ($(this).data("original").length > 0) {
                
                var imagesettings = $.extend({}, settings, $(this).data());
                
                var as = (imagesettings.addon_settings !== false ? imagesettings.addon_settings : "");
                
                if (settings.force_size === false && typeof $(this).data("force_size") === "undefined") {
                    
                    if(settings.log){
                        console.log("normal");
                    }
                    
                    var width = $(this).width();

                    if ($(this).attr("width") !== undefined) {
                        width = $(this).attr("width");
                    }
                    else {
                        width = $(this).parent().width();
                    }

                    if (width > $(this).parent().width()) {
                        var pwidth = $(this).parent().width();
                        if (pwidth === 0) {
                            width = $(this).parent().parent().width();
                        }
                        else {
                            width = pwidth;
                        }
                    }

                    $(this).attr({
                        width: "",
                        height: ""
                    });


                    if (settings.log === true)
                        console.log("width", width);


                    var options = "w_auto,c_limit" + as;
                    var cssValues = {
                        height: $(this).css("height"),
                        width: $(this).css("width")
                    }

                    // TESTING 
                    if (!$(".print-mode").is(":hidden")) {
                        // alternative print mode, not done    
                    }

                    // TESTING

                    //if(parseInt(cssValues.height) > 5 && parseInt(cssValues.width) > 5){
                    //    options  = "w_"+parseInt(cssValues.width)+",h_"+parseInt(cssValues.height)+",c_fill";
                    //}else{
                    options = "w_" + Math.round(width) + ",c_fill" + as;
                    //}

                    $(this).attr("width", width);
                    $(this).height("");


                    if (width >= $(this).parent().outerWidth()) {
                        width = $(this).parent().outerWidth();
                    }
                }else {
                    options = "w_" + $(this).attr("width") + ",h_" + $(this).attr("height") + ",c_fill" + as;
                    
                    if(settings.log)
                    console.log("extra");
                }

                
                var org = $(this).data("original");

                if (!$("body").hasClass("ineditmode") && $(this).data("original").match(",,")) {
                    org = $(this).data("original").split(",,")[0];
                    org = org.split("/EPiServer/CMS/Content")[1];
                }
                var url = "//res.cloudinary.com/ssp/image/fetch/" + options + "/";

                if (settings.log)
                    console.log(url);

                if (!org.match(/https|http/))
                    url = url + window.location.protocol + "//" + window.location.hostname + "/" + org;
                else
                    url = org;


                if (org.length > 0)
                    return url
            }
        });

        settings.skippedImages.attr("src", function(){
            return $(this).data("original");
        });

        if (typeof settings.callback === "function")
            settings.callback();
    }
};

$.extend(sspModules, plugin);