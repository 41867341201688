/* global $ sspModules localStorage */

var pluginData = {
    name: "createCollapse",
    version: "0.9",
    modified: "2016-11",
    selfrunning: false,
    dependencies: [
        "jQuery",
    ]
};

var plugin = {
    createCollapse: function(obj, callback) {

        if (typeof obj !== 'object' && typeof obj !== "undefined") {
            console.log('Dette er ikke et objekt');
            return;
        }
        
        var f = {
            openAccordian : function(contentObj){
        
                contentObj.slideDown(settings.transitionTimer, function() {

                    if (typeof sspModules.imageCompression !== "undefined") {

                        sspModules.imageCompression();
                    }

                });
                
                contentObj.addClass(settings.openClass);
                $(this).addClass(settings.openClass);
                
                // Is the open anchor
                if(contentObj.context.nodeName === 'A'){
                    $(contentObj.context).addClass(settings.linkOpenClass);
                }
            },
            
            closeAccordian : function(contentObj){
                contentObj.slideUp(settings.transitionTimer);
                contentObj.removeClass(settings.openClass);
                $(this).removeClass(settings.openClass);
                
                // Is the open anchor
                if(contentObj.context.nodeName === 'A'){
                    $(contentObj.context).removeClass(settings.linkOpenClass);
                }
            }
        }

        var defaults = {
            accord: $("article h5"),
            nextuntil: "h1,h2,h3,h4,h5,h6,div,.stopaccor",
            addonNextUntil: false,
            linkClass: "faq",
            openClass: "open",
            linkOpenClass:'collapse-open',
            contentClass: "collapse",
            transitionTimer: 85,
            imageReload: false,
            rem_open: false
        };

        var settings = $.extend(defaults, obj);


        $(settings.accord).each(function() {
            var domClasses = "";
            if(typeof $(this).classList !== "undefined"){
                if ((this).classList.length > 0) {
                    domClasses = $(this).attr("class");
                }
            
            }else {
                domClasses = $(this).attr("class");
            }
            
            $(this).nextUntil(settings.nextuntil + (settings.addonNextUntil !== false ? "," + settings.addonNextUntil : "")).wrapAll("<div class='" + settings.contentClass + " accor'></div>");
            $(this).replaceWith("<a href='#" + encodeURIComponent($(this).text()) + "' class='" + settings.linkClass + " " + domClasses + "'>" + $(this).text() + "</a>");

            if (settings.rem_open === true) {
                if (localStorage.getItem("accor:#" + encodeURIComponent($(this).text()))) {
                    $("a[href='#" + encodeURIComponent($(this).text()) + "']").click();
                }
            }
        });

        $("body").on("click","." + settings.linkClass,function(e) {
            e.preventDefault();
            
            
            var contentObj = $(this).next("." + settings.contentClass),
                status = contentObj.hasClass(settings.openClass) ? true : false;

            if (!status) {
                f.openAccordian(contentObj);
            }else {
                f.closeAccordian(contentObj);
            }

            if (settings.rem_open === true) {
                if ($(this).hasClass(settings.openClass)) {
                    localStorage.setItem("accor:" + $(this).attr("href"), true);
                }
                else {
                    localStorage.setItem("accor:" + $(this).attr("href"), false);
                }
            }
        });

        $(settings.accord).each(function() {
            if (settings.rem_open === true) {
                if (localStorage.getItem("accor:#" + encodeURIComponent($(this).text())) == "true") {
                    $("a[href='#" + encodeURIComponent($(this).text()) + "']").click();
                }
            }
        });



        if (settings.rem_open !== false) {

            localStorage.setItem($(this).attr("href"), true);
        }

        if (typeof callback === "function") {

            return callback();

        }
        else {
            return f;
        }
    }
};

$.extend(sspModules, plugin);
