/*

Sets default object and include most and always supported code. 

Dependencies: 
- jQuery

*/


var sspModules = {
    init : function(){
        
    }(), // runs when started
    run : function(){
        
    }, // runs on command.
    cookieWrite : function(name,value,days,callback,path) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        
        if(typeof path === "undefined")
        path = "";
        
        
        document.cookie = name + "=" + value + expires + "; path=/"+path;
        
        
        if(typeof callback === "function")
        callback();
        
    },
    cookieRead : function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    cookieDelete : function(name,callback,path) {
        
        if(typeof path === "undefined")
        path = "";
        
        sspModules.cookieWrite(name,"",-1,callback,path);
        
    },
    getUrlParam : function(url)  {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i=0; i<arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // in case params look like: list[]=thing1&list[]=thing2
      var paramNum = undefined;
      var paramName = a[0].replace(/\[\d*\]/, function(v) {
        paramNum = v.slice(1,-1);
        return '';
      });

      // set parameter value (use 'true' if empty)
      var paramValue = typeof(a[1])==='undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      paramValue = paramValue.toLowerCase();

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
        }
        // if no array index number specified...
        if (typeof paramNum === 'undefined') {
          // put the value on the end of the array
          obj[paramName].push(paramValue);
        }
        // if array index number specified...
        else {
          // put the value at that index number
          obj[paramName][paramNum] = paramValue;
        }
      }
      // if param name doesn't exist yet, set it
      else {
        obj[paramName] = paramValue;
      }
    }
  }

  return obj;
},

    showRunetimeBar : function(){
        
        //GET URL 
        
        /*
        
        var url_string = window.location.href,
        url = new URL(url_string);
        
        if(url_string.match("runtimemode")){
            var c = url.searchParams.get("runtimemode");
            if(c.length > 0){
                sspModules.cookieWrite("RuntimeMode",c);
            }
        }
        if(url_string.match("delete_runtime")){
            sspModules.cookieDelete("RuntimeMode");
        }
        
        if(sspModules.cookieRead("RuntimeMode")){
            $("body").prepend("<div class='runtimemode-msg'><div class='container'><div class='row'><div class='col-xs-12 text-center'>Du er nå på RunTimeMode: \""+sspModules.cookieRead("RuntimeMode")+"\". <a href='?delete_runtime=true'>Fjern runtimemode</a> |  Server: "+$("#machine").text()+"</div></div></div></div>");
        }
        
        */
    }
};//.init();
  