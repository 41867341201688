/* global $ sspModules L categoryIcon */


var plugin = {
    pluginData: {
        name: "ssp-map",
        version: "0.9.1 Beta",
        modified: "2017-01",
        selfrunning: false,
        dependencies: [
            "jQuery",
            '<script type="text/javascript" src="//maps.google.com/maps/api/js?v=3.2&amp;sensor=false"></script>', // optional
            "//cdnjs.cloudflare.com/ajax/libs/leafvar /0.6.4/leafvar .css",
            "//cdnjs.cloudflare.com/ajax/libs/leafvar /0.6.4/leafvar .js",
            "//prokomresources.prokomcdn.no/plugins/ssp-leafvar /leafvar -google.js",
            "//prokomresources.prokomcdn.no/plugins/ssp-leafvar /font-aw-markers.js"
        ]
    },
    
    map: function(config) { // Callback function eks: sspModules.myPlygin()

        var that = this, // if your object should return subfunctions 
            defaults; // Creates the object for defaults

        defaults = { // Set defaults eks: test:"test",
            mapData : null,
            map_url: "/modules/ssp.propono.core/PoiApi/PoiListSearch/?blockid=", //sarpsborg "/modules/ssp.geokart/PoiApi/PoiListSearch/?blockid=",
            start_location: [59.914306, 10.738013],
            map_type: "norges_grunnkart", // norges_grunnkart, terreng_norgeskart, norges_grunnkart_graatone, matrikkel_bakgrunn, egk
            first_optiontext: "Vis alle markører",
            map_container: $("[data-mapinit]"),
            data_name: 'mapinit',
            wheel_zoom: false,
            force_fit_pois:false,
            tap: false,
            dragging: true, // Cant scroll on mobile if true
            scrollWheelZoom:false,
            link_name: "Gå til side",
            marker_cluster: false,
            hide_levels : false,
            create_contact: {
                run: true,
                poi_address: ".poiblock-address",
                pre_class: ".editorial",
                post_function: false,
                address_text_plural: "Adresser",
                address_text: "Adresse",
                method: "append", // options: prepend, append
            },
            zoom_attrname : "zoom"
        };


        if (typeof config !== "object" && typeof config !== "undefined") { // 
            console.log("Error (" + this.pluginData.name + "): The module input must be an object. "); // Errormessage
            return;
        }

        var settings = $.extend(defaults, config); // mergin defaults with config. config as lead.

        var map = [],
            markersById = {}, 
            collection = [], // Old can possible delete
            markerCollectionByBlockId = {}, // Solution for multiple maps on one page
            
            categories = [], // Old can possible delete
            selectElementCreationData = {}, // Data used to create the select
            categoriesByBlockId={};
        
        var utils = {
            domCreate : function(elm, attrs){
                var attrs = attrs || {};
                var created = document.createElement(elm);
                Object.keys(attrs).forEach(function(key) {
                    switch (key) {
                        case "innerHTML":
                            created.innerHTML = attrs[key];
                            break;
                        case "class":
                            created.className = attrs[key];
                            break;
                        default:
                            created.setAttribute(key, attrs[key]);
                            break;
                    }
                });
    
                return created;
            },
            
            arraySort : {
                objKeyAlphabetically : function(a, b, key){
                    if(a[key] > b[key]){
                        return 1
                    }else if(a[key] < b[key]) {
                        return -1;
                    }else{
                        return 0;
                    }
                }
            }
        };
        
        var mapFunctions = { // collection of functions that are used in the plugin
            init: function() {
                
                if (settings.log)
                    console.log("Map: Init is running");

                $("[data-mapinit]").each(function(i, n) {
                    
                    var mapid = "map_" + i,
                        height = 360;

                    $(this).attr("id", mapid);

                    if ($(this).data("height") > 0) {
                        height = $(this).data("height");
                    }

                    $(this).css("height", height);

                    var map1,
                        singlePoiZoom = null,
                        zoom = 13; //Default
                        
                    if ($(this).data(settings.zoom_attrname)) {
                        zoom = $(this).data(settings.zoom_attrname);
                    }else if ($(this).data(settings.zoom_attrname) === 0) {
                        zoom = 0;
                    }

                    var map = mapFunctions.startmap({ id: mapid, zoom: zoom });
                    var mapInitId = $(this).data("mapinit");
                    
                    mapFunctions.get_markers(mapInitId, function() {
                        var geojson = {
                            "type": "FeatureCollection",
                            "features": markerCollectionByBlockId[mapInitId] //collection
                        }
                        
                        mapFunctions.insert_map({
                            marker_collection: geojson,
                            id: mapid,
                            zoom:zoom
                        });
                        
                        if (categoriesByBlockId[mapInitId].length > 1) {
                            mapFunctions.create_category_selector({ 
                                precoise: false, 
                                mapid: mapid, 
                                mapInitId : mapInitId,
                                marker_collection: geojson 
                            });
                        }
                    });
                });
            },
            
            create_category_selector: function(obj) {
                
                var markerCollection = obj.marker_collection;
                var domCreate = utils.domCreate;
                var sortAlphabetically = utils.arraySort.objKeyAlphabetically;
                
                $("<div class='map-functions'>").insertAfter("#" + obj.mapid);
            
                // Select with level
                var selectElement = domCreate('select');
                var allOption = domCreate('option',{
                    value : '*',
                    innerHTML : 'Vis alle markører'
                });

                selectElement.appendChild(allOption);
                
                selectElementCreationData[obj.mapInitId].sort(function(a,b){
                    return sortAlphabetically(a,b,'name');
                });
                
                selectElementCreationData[obj.mapInitId].forEach(function(mapDataItem){
                    
                    var mapOption = domCreate('option',{
                        value : mapDataItem.name + '|' + 'category',
                        innerHTML : mapDataItem.name
                    });
                    
                    
                    selectElement.appendChild(mapOption);
                    
                    if(!settings.hide_levels){
                        mapDataItem.geoList.forEach(function(geoItem){
                            var geoOption = domCreate('option',{
                                value : geoItem.Name + '|' + 'level',
                                innerHTML : '&nbsp;&nbsp;&nbsp;' + geoItem.Name
                            });
    
                            selectElement.appendChild(geoOption);
                        });
                    }
                });
                
                selectElement.addEventListener('change', function(event){
                    map[obj.mapid].remove();
                    mapFunctions.startmap({ id: obj.mapid, zoom: 0});
                    
                    mapFunctions.insert_map({
                        limit_filter: event.target.value,
                        marker_collection: obj.marker_collection,
                        id: obj.mapid
                    });
                });
                
                $("#" + obj.mapid).next('.map-functions').append(selectElement);
            },
            
            get_markers: function(blockid, callback) {
                
                if(!markerCollectionByBlockId[blockid]){
                    markerCollectionByBlockId[blockid] = [];
                }
                
                var generateData = function(mapData){
                    var countpos = 0;
                    var sortAlphabetically = utils.arraySort.objKeyAlphabetically;

                    $.each(mapData.GeoList, function(a, b) {
                        
                        var category = b.Description;
                        
                        categories.push(b.Description);
                        
                        if(!categoriesByBlockId[blockid]){
                            categoriesByBlockId[blockid] = [];
                        }
                        
                        if(!selectElementCreationData[blockid]){
                            selectElementCreationData[blockid] = [];
                        }
                        
                        categoriesByBlockId[blockid].push(b.Description);
                        
                        b.GeoList.sort(function(a,b){
                            return sortAlphabetically(a,b,'Name');
                        });
                        
                        selectElementCreationData[blockid].push({
                            id: b.ID,
                            name : b.Name,
                            geoList : b.GeoList
                        });
                    
                        $.each(b.GeoList, function(c, pos) {

                            countpos++;

                            var url = "";

                            if (pos.Url) {
                                url = "<a href='" + pos.Url + "' class='buble-link'>" + (pos.Url.match("http") ? pos.Url : settings.link_name) + "</a>";
                            }

                            collection.push({
                                "type": "Feature",
                                "properties": {
                                    "popupContent": "<h3 style='margin:0; padding:0; margin-bottom:10px;' class='"+(pos.Name.length > 30 ? "long-text" : "")+"'>" + pos.Name + "</h3><p>" + pos.Gatenavn + "</p>" + pos.MainBody + url,
                                    "id": countpos,
                                    "typeunit": category,
                                    fulldata: pos
                                },
                                "geometry": {
                                    "type": "Point",
                                    "coordinates": [pos.Longitude, pos.Latitude]
                                }
                            });
                            
                            markerCollectionByBlockId[blockid].push({
                                "type": "Feature",
                                "properties": {
                                    "popupContent": "<h3 style='margin:0; padding:0; margin-bottom:10px;' class='"+(pos.Name.length > 30 ? "long-text" : "")+"'>" + pos.Name + "</h3><p>" + pos.Gatenavn + "</p>" + pos.MainBody + url,
                                    "id": countpos,
                                    "typeunit": category,
                                    fulldata: pos
                                },
                                "geometry": {
                                    "type": "Point",
                                    "coordinates": [pos.Longitude, pos.Latitude]
                                }
                            });
                        });
                    });

                    if (typeof callback === "function") {
                        callback(collection);
                    }
                    
                };
                
                if(settings.mapData){
                    generateData(settings.mapData);
                }else{
                    $.getJSON(settings.map_url + blockid).done(function(data) {
                        generateData(data);
                    });
                }
            },
            insert_map: function(obj) {
                
                if (settings.marker_cluster) {
                    var markers = L.markerClusterGroup(),
                        mapid = obj.id,
                        limit_filter = obj.limit_filter,
                        blockId = obj.blockId,
                        marker_collection = obj.marker_collection;

                    var geojson = L.geoJSON(marker_collection, {

                        pointToLayer: function(feature, latlng) { 
                            return L.marker(latlng, {icon: L.divIcon({
                            className: 'local-mapicon ' + feature.properties.typeunit,
                            shadowUrl: '//prokomresources.prokomcdn.no/client-grunt/02-standard-plugins/ssp-map/marker-shadow.png',
                            html : '<span class="map__inner__shadow"></span><span class="map__inner__border"></span>',
                            
                            iconSize:     [25, 25], // size of the icon
                            shadowSize:   [41, 41], // size of the shadow
                            iconAnchor:   [12, 41], // point of the icon which will correspond to marker's location
                            shadowAnchor: [41, 41],  // the same for the shadow
                            popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
                        })});

                        },
                        onEachFeature: function(feature, layerinfo) {
                            if (feature.properties) {

                                var content = feature.properties.popupContent;

                                layerinfo.bindPopup(content, {
                                    closeButton: true
                                });

                                // Save the layer into markersById if it has an id.
                                if (feature.properties.id) {
                                    markersById[feature.properties.id] = layerinfo;
                                }
                            }
                        },
                        filter: function(feature) {
                            if (typeof limit_filter !== "undefined") {
                                if (feature.properties.typeunit === limit_filter) return true;
                            }
                            else {
                                return true;
                            }
                        }
                    });

                    markers.addLayer(geojson);
                    map[mapid].addLayer(markers);
                    map[mapid].fitBounds(markers.getBounds());
                    
                }else {
                    var mapid = obj.id,
                        limit_filter = obj.limit_filter,
                        filterData = null,
                        marker_collection = obj.marker_collection;
                        
                    if(obj.limit_filter){
                        filterData = limit_filter.split("|").reduce(function(acc, text, index){
                            var key = index === 0 ? 'filter':'type';
                            acc[key] = text;
                            return acc;
                        },{});
                    }
                        
                    var geojson = L.geoJSON(marker_collection, {

                        pointToLayer: function (feature, latlng) {
                            return L.marker(latlng, {icon: L.divIcon({
                                className: 'local-mapicon ' + feature.properties.typeunit,
                                shadowUrl: '//prokomresources.prokomcdn.no/client-grunt/02-standard-plugins/ssp-map/marker-shadow.png',
                                html : '<span class="map__inner__shadow"></span><span class="map__inner__border"></span>',
                            
                                iconSize:     [25, 25], // size of the icon
                                shadowSize:   [41, 41], // size of the shadow
                                iconAnchor:   [12, 41], // point of the icon which will correspond to marker's location
                                shadowAnchor: [41, 41],  // the same for the shadow
                                popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
                            })});
                        },
                        onEachFeature: function(feature, layerinfo) {
                            if (feature.properties) {

                                var content = feature.properties.popupContent;

                                layerinfo.bindPopup(content, {
                                    closeButton: true
                                });

                                // Save the layer into markersById if it has an id.
                                if (feature.properties.id) {
                                    markersById[feature.properties.id] = layerinfo;
                                }
                            }
                        },
                        
                        filter: function(feature) {
                            if(typeof limit_filter === 'undefined' || limit_filter === '*'){
                                return true;
                            }
                            
                            var typeunit = feature.properties.typeunit.trim();
                            var levelName = feature.properties.fulldata.Name.trim();
                            var levelZoom = feature.properties.fulldata.MapZoom;
                        
                            switch(filterData.type){
                                case 'category':
                                    return typeunit === filterData.filter;
                                case 'level':
                                    obj.singlePoiZoom = levelZoom;
                                    return levelName === filterData.filter;
                            }
                        }
                    }).addTo(map[mapid]);
                    
                    // Fitbounds callback
                    map[mapid].once("moveend",function(event){
                        event.target.off("moveend");
                        
                        if(!settings.force_fit_pois && obj.zoom){
                            console.log("goes here???", obj.zoom);
                            event.target.setZoom(obj.zoom);
                        }
                        
                        // Able to customize zoom level on singlePoi
                        // when triggering change event on the select element
                        if(!settings.force_fit_pois && obj.singlePoiZoom){
                            map[mapid].setZoom(obj.singlePoiZoom);
                        }
                    });
                    
                    map[mapid].fitBounds(geojson.getBounds());
                }

                if (marker_collection.features.length == 1) {
                    markersById['1'].openPopup();
                }

                $(".able-show").removeClass("hidden");

            },
            get_address: function(adresse) {
                return $.get("//ws.geonorge.no/AdresseWS/adresse/sok?sokestreng=" + adresse + "&antPerSide=1").done(function(md) {

                });
            },
            startmap: function(obj) {
                
                var that = this;
                
                map[obj.id] = L.map(obj.id, {
                    zoomControl: true,
                    tap: settings.tap,
                    dragging:settings.dragging,
                    scrollWheelZoom:settings.scrollWheelZoom
                }).setView(settings.start_location, obj.zoom); //settings.zoom
                

                L.tileLayer('//opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=' + settings.map_type + '&zoom={z}&x={x}&y={y}', {
                    attribution: '<a href="http://www.kartverket.no/">Kartverket</a>'
                }).addTo(map[obj.id]);
                
                
                that.create_contact();
                

            },
            create_contact: function() {
                if (settings.create_contact.run === true) {
                    var poi_address = $("" + settings.create_contact.pre_class + " " + settings.create_contact.poi_address + "");
                    var poi_address_length = poi_address.length;
                    if (poi_address_length > 0) {
                        // each map adress
                        if (settings.create_contact.method === "prepend") {
                            $(".article-content").prepend("<h2>" + (poi_address_length > 1 ? settings.create_contact.address_text_plural : settings.create_contact.address_text) + "</h2><div id='mappush'></div></div>");
                        }
                        else {
                            $(".article-content").append("<h2>" + (poi_address_length > 1 ? settings.create_contact.address_text_plural : settings.create_contact.address_text) + "</h2><div id='mappush'></div></div>");
                        }
                        $("#mappush").append(poi_address);
                        $("#mappush " + settings.create_contact.poi_address + "").addClass("placed");
                    }

                    if (typeof settings.create_contact.post_function === "function") {
                        settings.create_contact.post_function();
                    }
                }
            }
        };

        var map_container = settings.map_container;

        if (typeof map_container !== "undefined") {
            if (map_container.length > 0) {
                mapFunctions.init();
            }
        }
        // enkeltkart 2882
        //samlekart 5393 
    },
};

$.extend(sspModules, plugin); // adding plugin to