/* global $ sspModules */

var plugin = {
    pluginData: {
        name: "myPlugin",
        version: "0.0.1 Beta",
        modified: "2016-11",
        selfrunning: false,
        dependencies: [
            "jQuery",
        ]
    },
    articleNav: function(config) { // Callback function eks: sspModules.myPlygin()

        var that = this, // if your object should return subfunctions 
            defaults; // Creates the object for defaults

        var defaults = {
            articleHeader: $("article h2"),
            articleheaderClass:'',
            convertHeader: false,
            untilObj: "h2",
            wrapClass: "articleBody",
            resetNav: true,
            include_after: $(".scrollspy .include-after"),
            navElements: $("article h2, article h3.submenu"),
            aClass: "",
            liClass:"",
            affixTopDistance: "article",
            affixMapId: "#map",
            affixFooter: "footer",
            affixMapAndFooter: 420,
            directlink: false,
            affixNoMapFooter: 30,
            no_menu: ".nomenu",
            log: false,
            only_part: false,
            manual_bottom_offset: false,
            autoGotoSection: false
        };

        if (typeof config !== "object" && typeof config !== "undefined") { // 
            console.log("Error (" + this.pluginData.name + "): The module input must be an object. "); // Errormessage
            return;
        }

        var settings = $.extend(defaults, config); // mergin defaults with config. config as lead.

        if (settings.log)
            console.log("defaults", defaults);

        if (settings.log)
            console.log("articleNav: is running", settings);



        /// PL START

        if (typeof obj !== "object" && typeof obj !== "undefined") {
            console.log("Nope! Her må du ha objekt.");
            return;
        }

        var f = {

            headerToNav: function(obj) {

                // Nullstille #nav
                if (settings.resetNav) {
                    $("#nav").html("");
                }

                if ($("#nav").length < 1) {
                    if (settings.log)
                        console.error("Error: Har ikke #nav");
                    return;
                }

                var headingnr = 0;

                settings.navElements.each(function() {

                    if (!$(this).hasClass("nomenu")) {
                        headingnr++;
                        var tagName = $(this).prop("nodeName").toLowerCase(),
                            idName = "heading-" + tagName + "-" + headingnr;
                        
                        $(this).attr("id", idName);
                        
                        if(settings.articleheaderClass != ''){
                            $(this).addClass(settings.articleheaderClass);
                        }
                        
                        $("#nav").append("<li class='" + settings.liClass + "'" + tagName + "'><a href='#" + idName + "' class='" + settings.aClass + "'>" + $(this).text() + "</a></li>");
                    }
                });

            },
            urlify: function(s) {
                return s.replace(/[^A-Za-z0-9_]/g, "-").replace(/-{2,}/g, "-").replace(/^-/g, "").replace(/-$/g, "").toLowerCase();
            },
            createParts: function() {
                var that = this;
                if (settings.articleHeader.length > 0) {

                    var headingnr = 0;

                    $.each(settings.articleHeader, function() {
                        var name = that.urlify($(this).text());

                        headingnr++;


                        var tagName = $(this).prop("nodeName").toLowerCase(),
                            idName = "heading-" + tagName + "-" + headingnr;

                        if (typeof settings.convertHeader === "string") {

                            if (settings.log === true){
                                console.log("header converted", name);
                            }
                            
                            $(this).replaceWith($('<' + settings.convertHeader + ' id="' + idName + '">' + this.innerHTML + '</' + settings.convertHeader + '>'));
                        }


                        $(this).nextUntil(settings.untilObj).andSelf().wrapAll("<div class='" + settings.wrapClass + "' data-name='" + name + "'></div>");

                    });
                }
            },
            
            add_link: function() {
                var that = this;

                $("." + settings.wrapClass + "").each(function() {

                    if ($(this).children("p").length <= 1) {

                        $(this).children("p").each(function() {

                            /*if($(this).html().match(/^\<span\>\<a/) || $(this).html().match(/^\<a/)){
                                
                                console.error("ok, starts with span or a");
                                
                            }*/
                            if ($(this).children(":first").is("a")) {

                                var id = $(this).prev("h2,h3").attr("id");


                                $("a[href='#" + id + "']").attr("href", $(this).children(":first").attr("href"));

                            }
                            if ($(this).children(":first").is("span")) {

                                var id = $(this).prev("h2,h3").attr("id");


                                $("a[href='#" + id + "']").attr("href", $(this).children(":first").children("a").attr("href"));

                            }
                        });
                    }
                });
            }
        }

        f.createParts();

        if (settings.only_part === false) {

            f.headerToNav();

            if ($("#nav").length > 0 && $(window).width() > 767) {

                var topMarginNav;

                if (typeof settings.affixTopDistance === "number"){
                    topMarginNav = settings.affixTopDistance;
                }else{
                    topMarginNav = $(settings.affixTopDistance).offset().top;
                }


                if (settings.log === true)
                    console.log("Type of affixTopDistance", typeof settings.affixTopDistance);
                
                
                
                console.warn('Affix disabled in bootstrap 4');
                // $('#nav').affix({
                //     offset: {
                //         top: topMarginNav,
                //         bottom: function() {
                //             if (settings.manual_bottom_offset === false) {
                //                 if ($(settings.affixMapId).length > 0) {

                //                     return $(settings.affixFooter).outerHeight() + settings.affixMapAndFooter;
                //                 }
                //                 else {

                //                     return $(settings.affixFooter).outerHeight() + settings.affixNoMapFooter;

                //                 }
                //             }
                //             else {
                //                 return settings.manual_bottom_offset;
                //             }
                //         }
                //     },
                // });
            }

        }

        if (settings.directlink === true) {

            f.add_link();
        }

        if (settings.include_after.length > 0) {
            var li = $("<li>");

            li.append(settings.include_after);

            $("#nav").append(li);
        }
        // PL STOP
    },
};

$.extend(sspModules, plugin); // adding plugin to
