/* global $ sspModules */

var pluginData = {
    name: "createGallery",
    version: "0.9",
    modified: "2016-11",
    selfrunning: false,
    dependencies: [
        "jQuery",
        "fancybox"
    ]
};

var plugin = {
    createGallery: function(config) {
        var that = this,
            defaults;
            
        defaults = {
            eachContainer: $("article .articleBody"), // Defines where to search for images
            minImageCount: 1, // Defines how many images before installation of gallery 
            findImg: "img",
            skipclass : ".skip-gallery",
            fancyboxAttr: {
                caption: {
                    type: 'float'
                },
                openEffect: 'drop',
                closeEffect: 'drop',
                nextEffect: 'elastic',
                prevEffect: 'elastic',
                type:'image',
            },
            title: "title", // HTML  
            alt: "alt",
            log:false
        };

        if (typeof config !== "object" && typeof config !== "undefined") {
            console.log("Create gallery: Har ikke definert Objt.");
            return;
        }

        var settings = $.extend(defaults,config);
    
        if(settings.log)
        console.log("settings, defaults:", settings, defaults);
    
        // Create gallery function
        
        var galleryid = 0;
        
        if(settings.log)
        console.log("length each", settings.eachContainer.length);
    
        
        $.each(settings.eachContainer, function(a,b) {
            
            if(settings.log)
            console.error("test",a);
            
            
            var images = $(this).find(settings.findImg + ':not(' + settings.skipclass + ')');
            
            settings.log ? console.log("each image:",images, images.length) : "";

            if (images.length > settings.minImageCount) {
                
                settings.log ? console.log("apply imageclass") : '';

                images.addClass("gallery-image").wrap(function() {
                    return ($(this).parent("a").length < 1 ? "<a class='gallery-image-link'>" : "");
                });
                
                images.parent("a").attr({
                    "data-fancybox":"group",
                    "rel": "gallery_" + galleryid,
                    "href": function() {
                        
                        
                        if(typeof $(this).children("img").attr("data-original") !== "undefined"){
                            if($(this).children("img").attr("data-original").match(",")){
                            return $(this).children("img").attr("data-original").split(",")[0];
                            }
                            else {
                            return $(this).children("img").attr("data-original");
                                
                            }
                        }
                        else {
                            return $(this).children("img").attr("src");
                        }
                    },
                    "title": function() {
                        var title = $(this).children("img").attr("title");
                        if(typeof title == "undefined"){
                            title = $(this).children("img").attr("alt");
                        }
                        return title
                    }
                }).fancybox(settings.fancyboxAttr);

                galleryid++;

            }

        });

    },
};

$.extend(sspModules, plugin);
